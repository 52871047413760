import { createRouter, createWebHashHistory } from "vue-router";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "produk list",
    component: () => import("../views/List.vue"),
  },
  {
    path: "/promo",
    name: "promo list",
    children: [
      { path: "", component: () => import("../views/Promo.vue") },
      {
        path: "detail/:id",
        component: () => import("../views/Promo-Detail.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "home",
    meta: {
      noAuth: true,
    },
    component: () => import("../views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let temp_token = null;
  // if (process.env.NODE_ENV == "development") {
  //   // ADMIN
  //   temp_token =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaWdubyBTb2Z0d2FyZSBTb2x1dGlvbiIsImV4cCI6MTY2MDQ0ODk1NSwiZGF0YSI6eyJpZCI6IjEiLCJ1c2VybmFtZSI6ImFkbWluIiwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJkZXBhcnRtZW50IjoiQURNSU5JU1RSQVRPUiIsImlzX21hbmFnZXIiOiIxIiwiaXNfc3RhZmYiOiIwIiwiYXBwX3NhbGVzX2lzX3N1cGVydmlzZWQiOiIwIn19.ihFAyVuuimxABkvAl6pQGkaVxBfO-tCrfyCzz1Rwfp4";

  //   localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, temp_token);
  // }

  let token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  if (to.meta.noAuth) {
    next();
  } else if (token === null) {
    // console.log("no token");
    next("/login");
  } else {
    try {
      let resp = await axios.post(`${process.env.VUE_APP_BASE_URL}/user_data`, {
        token: token,
      });
      to.meta.user = resp.data;
      next();
    } catch (error) {
      console.log(error);
      console.log("gagal menarik data user");
      localStorage.clear();
      next("/login");
    }
  }
});

export default router;
