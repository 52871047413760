<template>
  <q-layout view="lHh Lpr lFf">
    <q-header v-if="!route.meta.noAuth" class="bg-white text-primary">
      <q-toolbar>
        <q-toolbar-title class="text-bold text-brand">
          Promo Seeker App
        </q-toolbar-title>

        <div class="row items-center q-gutter-x-sm">
          <q-tabs active-color="brand" class="text-grey-6" no-caps>
            <q-route-tab to="/" label="List Product" />
            <q-route-tab to="/promo" label="List Promo" />
          </q-tabs>
          <!-- <q-btn
            flat
            dense
            no-caps
            color="grey-8"
            to="/"
            label="List Produk"
          ></q-btn>
          <q-btn
            flat
            dense
            no-caps
            color="grey-8"
            to="/promo"
            label="List Promo"
          ></q-btn> -->
          <q-btn
            class="q-ml-md"
            color="negative"
            label="Logout"
            @click="logout"
            unelevated
            no-caps
          ></q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-page-container class="bg-grey-3">
      <router-view class="row justify-center" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import useBasic from "./composables/useBasic";
export default {
  name: "LayoutDefault",

  setup() {
    const { route, router, $http, onMounted, reactive } = useBasic();

    let logout = () => {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      router.push("/login");
    };

    let data = reactive({
      tab: "/",
    });

    onMounted(async () => {});

    return { route, logout, data, router };
  },
};
</script>
<style lang="scss">
.mypage {
  max-width: 1440px;
  margin: 0 auto;
}
.text-brand {
  color: #3d3099 !important;
}
.bg-brand {
  background: #3d3099 !important;
}
@import "@/styles/quasar.scss";
.stickyTable {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
    color: black;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
    font-weight: bold !important;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.l-container {
  height: 100vh;
}
.l-mobile-container {
  max-width: 1280px;
  min-width: 300px;
  height: calc(100vh - 50px);

  margin: 0 auto;
}
.l-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99;
}
.border-radius {
  border: 1px solid #bbbbbb;
  border-radius: 10px;
}
</style>
