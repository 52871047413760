import { useRouter, useRoute } from "vue-router";
import { ref, reactive, inject, watch, onMounted, computed } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
moment.locale("id");
export default function useBasic() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const $q = useQuasar();
  const thousand = inject("thousand");
  const rupiah = inject("rupiah");

  let isLoadingData = ref(false);
  let loadThis = async (fun) => {
    isLoadingData.value = true;
    await fun();
    isLoadingData.value = false;
  };
  let waitThis = async (fun) => {
    $q.loading.show();
    await fun();
    $q.loading.hide();
  };

  let sessionMoment = (a, b) => {
    if (a == "" || b == "") return "-";
    let str = `${moment.unix(a).format("dddd, DD MMM YYYY HH:mm")} - ${moment
      .unix(b)
      .format("HH:mm")}`;

    if (!moment.unix(a).isSame(moment.unix(b), "day")) {
      str = `${str} (+ ${
        parseInt(moment.unix(b).format("D")) -
        parseInt(moment.unix(a).format("D"))
      } Hari)`;
    }

    return str;
  };

  return {
    router,
    route,
    $http,
    $q,
    isLoadingData,
    ref,
    reactive,
    watch,
    onMounted,
    computed,
    loadThis,
    waitThis,
    moment,
    thousand,
    rupiah,
    sessionMoment,
  };
}
